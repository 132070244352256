@import "src/styles/main";

// Header
$header-height: 45;
$header-bg-color: $colorSecondary;
$header-border-color: $colorPrimary;
$header-box-shadow-color: rgba(0, 0, 0, 0.8);

// Header menu
$header-menu-color-selected: lighten($header-bg-color, 10%);
$header-menu-border-color: $menuBorderColor;

// Header menu text
$header-menu-text-color: white;
$header-menu-text-subtitle-color: darken($header-menu-text-color, 20%);

// Header menu content
$header-menu-content-bg-color: white;

// Header menu item
$header-menu-item-color-selected: lighten($colorDivider, 5%);

// Header content
$header-content-height: $header-height * 0.8;

.header {
  position: fixed;
  left: 200px;
  transition: width, left, right, 0.3s;
  @media screen and (max-width: 760px) {
    left: 80px;
  }
  top: 0;
  right: 0;
  z-index: 10000;
  height: remCalc($header-height);
  @include flex-row;
  align-items: center;
  color: $header-menu-text-color;
  background-color: $colorPrimary;
  box-shadow: 0 0 emCalc(10) 0 $header-box-shadow-color;
  user-select: none;

  &__left {
    @include flex-row;
    height: 100%;

    .header-menu .header-menu-content {
      right: auto;
      left: 0;
    }
  }

  &__right {
    @include flex-row;
    height: 100%;
    margin-left: auto;

    .header-menu .header-menu-content {
      left: auto;
      right: 0;
    }
  }
}

.header-logo {
  display: flex;
  align-items: center;
  padding: 0 1vw;
  img {
    width: 5em;
    height: auto;
  }
}

.header-searchbar {
  display: flex;
  height: emCalc($header-content-height);
  background-color: white;
  margin: emCalc(12);
  border-radius: emCalc(3);
}

.header-searchbar-label {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: none;
  color: $colorPrimary;
  justify-content: center;
  align-items: center;
  border-radius: emCalc(3);
  background-color: $colorSecondary;
  margin: emCalc(4);
  padding: emCalc(4);
}

.header-searchbar-input {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  font-size: emCalc(18);
  outline: 0;
  border: 0;
  background: transparent;
}

/** Menu item base style */
.header-menu {
  position: relative;
  border-left: emCalc(1) solid $header-menu-border-color;
  font-size: emCalc(14);
  padding: 0 emCalc(10);
  cursor: pointer;
  &:hover,
  &:focus {
    background: $header-menu-color-selected;
  }
}

.header-menu--open {
  background: $header-menu-color-selected;
}

/**
Header Menu Button
principal part of the header menu (visible)
*/

.header-menu-button {
  text-decoration: none;
  font-size: remCalc(16);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 0;

  &__icon {
    margin-right: remCalc(5);
    width: remCalc(24);
    height: remCalc(24);
    fill: $header-menu-text-color;
  }

  &__text {
    @include flex-column;
    text-align: right;

    &__subtitle {
      // margin-top: remCalc(2);
      font-size: remCalc(14);
      font-weight: $fontWeightLight;
      color: $header-menu-text-subtitle-color;
    }
  }

  &__arrow {
    transition: transform 0.2s ease-out;
    margin-left: emCalc(5);
    width: emCalc(24);
    height: emCalc(24);
    fill: $header-menu-text-color;
    &__nav {
      transition: transform 0.2s ease-out;
      margin-left: emCalc(5);
      width: emCalc(28);
      height: emCalc(28);
      fill: $header-menu-text-color;
      margin-right: emCalc(5);
      margin-left: auto;
    }
  }
}

.header-menu-button--open {
  &__arrow {
    transition: transform 0.2s ease-out;
    transform: rotate(-180deg);
  }
}

.header-item-container {
  // width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
}

/**
Header Menu Content
hidden part of the header menu (dropdown)
*/
.header-menu-content {
  position: absolute;
  visibility: hidden;
  // margin-top: remCalc(5);
  padding: remCalc(5) remCalc(0);
  min-width: remCalc(150);
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
  background-color: $header-menu-content-bg-color;
  color: black;
  border-radius: remCalc(2);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24);
  overflow-x: hidden;
  overflow-y: auto;

  &__section {
    border-top: emCalc(1) solid rgb(232, 232, 232);
    // margin-top: remCalc(5);

    &__title {
      cursor: auto;
      font-weight: $fontWeightBold;
      padding: emCalc(8) emCalc(12);
      // margin-top: remCalc(5);
    }

    &:first-child {
      border: none;
      margin: 0;
    }
  }

  &__item {
    @include flex-row;
    position: relative;
    align-items: center;
    padding: emCalc(8) emCalc(12);
    cursor: pointer;
    min-height: emCalc(40);
    min-width: emCalc(160);
    &:hover,
    &:focus {
      background: $header-menu-item-color-selected;
    }

    &__icon {
      margin-right: emCalc(15);
      width: emCalc(24);
      height: emCalc(24);
      fill: black;
    }

    &__text {
      padding: emCalc(20) emCalc(10);
      margin: emCalc(-20) emCalc(-10);
      color: black;
      text-decoration: none;
      display: block;
    }

    &__parent {
      /* position: relative; */
      align-items: center;
    }
  }

  &__arrow {
    transition: transform 0.2s ease-out;
    margin-left: emCalc(5);
    width: emCalc(24);
    height: emCalc(24);
    fill: black;
    &__nav {
      transition: transform 0.2s ease-out;
      margin-left: emCalc(5);
      width: emCalc(28);
      height: emCalc(28);
      fill: black;
      margin-right: emCalc(5);
      margin-left: auto;
    }
  }
}

.header-menu-content--open {
  z-index: 999999;
  visibility: visible;
  &__arrow {
    transition: transform 0.2s ease-out;
    transform: rotate(-180deg);
  }
}

.highlightedMenu {
  background-color: #424439;
}
