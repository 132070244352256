@import "variables";

/* Calculate rem-value from a given pixel-size */
// Maybe use $browser-context instead of $base:16
@function remCalc($px, $base: $browser-default-font) {
  $remSize: $px / $base;
  @return #{$remSize}rem;
}

/* Calculate em-value from a given pixel-size */
// Maybe use $browser-context instead of $base:16
@function emCalc($px, $base: $browser-default-font) {
  $emSize: $px / $base;
  @return #{$emSize}em;
}
