@import "functions";

/* Colors */
@mixin disableColor($rgbColor) {
  background-color: rgba($rgbColor, 0.5);
}

/* Disable default a style */
@mixin clear-default-link {
  color: black;
  text-decoration: none;
}

/* Flex boxes */
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

/* Scrollbar */
@mixin scrollable(
  $xValue: hidden,
  $yValue: scroll,
  $trackColor: $colorDivider,
  $thumbColor: $colorThird
) {
  overflow-x: $xValue;
  overflow-y: $yValue;

  /* Scrollbar */
  &::-webkit-scrollbar {
    width: emCalc(10);
  }

  /* The track (progress bar) */
  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  /* The dragable scrolling handle */
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;

    &:hover {
      background: $colorSecondary;
    }
  }
}

@mixin scrollable(
  $xValue: hidden,
  $yValue: auto,
  $trackColor: $colorDivider,
  $thumbColor: $colorThird
) {
  overflow-x: $xValue;
  overflow-y: $yValue;

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: $thumbColor $trackColor;

  /* Scrollbar */
  &::-webkit-scrollbar {
    width: emCalc(5);
  }

  /* The track (progress bar) */
  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  /* The dragable scrolling handle */
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;

    &:hover {
      background: $colorSecondary;
    }
  }
}
