@import "src/styles/main";
@import "src/app/components/header/header";

$color-primary: $colorPrimary;
$color-secondary: #e6e6e6;
$color-alert: #d9534f;

@mixin btn-type($hexaColor) {
  background-color: $hexaColor;

  &:hover:enabled {
    background-color: darken($hexaColor, 10%);
  }
  &:disabled {
    cursor: not-allowed;
    @include disableColor($hexaColor);
  }
}

.btn-table {
  padding: emCalc(3) emCalc(3) emCalc(3) emCalc(3) !important;
  margin-bottom: emCalc(10);
  margin-right: 0 !important;
  min-height: emCalc(32);
  min-width: 0 !important;
  width: emCalc(80) !important;
  font-size: emCalc(12) !important;
  user-select: none;
  cursor: pointer;
}

.btn {
  padding: emCalc(10) emCalc(10);
  color: $colorSecondary;
  border: 0;
  border-radius: emCalc(3);
  outline: 0;
  min-height: emCalc(32);
  min-width: emCalc(64);
  font-size: emCalc(14);
  text-transform: initial;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  @include btn-type($color-primary);

  &__icon {
    width: emCalc(12);
    height: emCalc(12);
  }
}

.btn-card {
  @extend .btn;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: $colorPrimary;
  margin: emCalc(4);

  &__disabled {
    background-color: $colorThird;
  }

  &__cancel {
    background-color: $colorBoschungRed;
  }
}

.btn-header {
  background-color: $colorSecondary;
  color: $colorSecondary;
  border: 0;
  border-radius: emCalc(3);
  min-width: emCalc(64);
  font-size: emCalc(14);
  text-transform: initial;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  margin: emCalc(4);

  &:hover:enabled {
    background-color: $colorThird;
  }
  &__icon {
    fill: $colorPrimary;
    width: emCalc(24);
    height: emCalc(24);
  }
}

.btn-sidebar {
  @extend .btn;
  margin: 0;
  width: 100%;
  border: $colorSecondary 1px;
  border-radius: 0;
  height: remCalc($header-height);
}

.MuiIcon-root {
  width: auto !important;
  height: auto !important;
  overflow: inherit !important;
}

.btn-danger {
  color: white;
  @include btn-type($color-alert);
}

.btn-secondary {
  @include btn-type($color-secondary);
}

.btn-icon-text {
  margin: auto;
  margin-top: emCalc(12);
  padding: emCalc(10);
  max-width: emCalc(350);
  display: block;
  width: 100%;

  svg {
    width: emCalc(12);
    height: emCalc(12);
  }
}

.btn-icon-form {
  padding: 0;
  margin: emCalc(2);
  margin-top: emCalc(11);
  margin-left: emCalc(11);
  min-width: emCalc(32);
  min-height: emCalc(32);

  svg {
    width: emCalc(12);
    height: emCalc(12);
  }
}

.btn-icon {
  padding: 0;
  margin: emCalc(2) emCalc(2) emCalc(2) emCalc(20);
  min-width: emCalc(32);
  min-height: emCalc(32);

  svg {
    width: emCalc(18);
    height: emCalc(18);
  }
}

.btn-icon-circle {
  padding: 0;
  margin: auto;
  margin-top: emCalc(5);
  margin-bottom: emCalc(5);
  min-width: emCalc(32);
  background-color: $color-primary !important;
  cursor: auto !important;
  display: flow-root;
  border-radius: 100%;
  svg {
    width: emCalc(12);
    height: emCalc(12);
  }
}

.btn-icon-reversed {
  padding: 0;
  margin: emCalc(2);
  margin-left: emCalc(11);
  min-width: emCalc(32);
  min-height: emCalc(32);

  svg {
    width: emCalc(12);
    height: emCalc(12);
    transform: rotate(180deg);
  }
}

.btn-menu {
  padding: 0;
  margin: 0;
  min-width: emCalc(52);
  min-height: emCalc(30);

  svg {
    width: emCalc(24);
    height: emCalc(24);
  }
}

.btn-xs {
  padding: emCalc(1) emCalc(5);
  font-size: emCalc(12);
}

.btn-sm {
  padding: emCalc(5) emCalc(10);
  font-size: emCalc(12);
}
