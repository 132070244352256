@import "src/styles/main";

.sign-box {
  min-width: emCalc(300);
  max-width: emCalc(350);
  min-height: emCalc(300);
  max-height: emCalc(600);
  background-color: white;
  padding: emCalc(30) emCalc(30) emCalc(10) emCalc(30);
  box-shadow: emCalc(5) emCalc(5) emCalc(5) 0 rgba(0, 0, 0, 0.5);

  &__header {
    margin-bottom: emCalc(30);
    text-align: center;

    &__logo {
      width: emCalc(150);
    }
  }
}
