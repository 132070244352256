@import 'src/styles/main';

// Your variable overrides
$sidebar-bg-color: $colorSecondary;
$sidebar-color: #adadad !default;
$sidebar-width: 200px;
$sidebar-collapsed-width: 80px !default;
$highlight-color: $colorPrimary;
$submenu-bg-color: #3d3d3d;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: $colorPrimary;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import '../../../../node_modules/react-pro-sidebar/dist/scss/styles';

.sidebar {
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 20000; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
}

.sidebar-icon {
  color: $colorSecondary;
}
