@import "functions";

html {
  font-family: neue-haas-unica, sans-serif;
  font-size: 100%; /* default browser size : 100% = 16px*/
  font-weight: $fontWeightRegular;
  font-style: normal;
}

html,
body,
.app {
  height: 100vh;
  //overflow: hidden;
}

html {
  overflow:auto;
}

/* Reset default a style */
a {
  color: black;
  text-decoration: none;
}

/* Application Wrapper */
.app-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.app-module {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
  margin-top: 45px;
  margin-left: 200px;
  transition: width, left, right, 0.3s;
  @media screen and (max-width: 760px) {
    margin-left: 80px;
  }
}

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $colorThird;
  padding: emCalc(24);
}

.flex-container {
  display: flex;
  flex-grow: 1;
  &__column {
    @extend .flex-container;
    flex-direction: column;
  }
  &__row {
    @extend .flex-container;
    flex-direction: row;
  }
  &__center {
    @extend .flex-container;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
  }
}

.custom-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #333333;
  border-radius: emCalc(12);
  margin: emCalc(12);
  padding: emCalc(24);
  width: 40%;
}

.custom-card-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.custom-card-table-container {
  max-height: 70vh;
}

.sign-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: emCalc(24);
  background-color: $colorSecondary;
  justify-content: center;
  align-items: center;
}

.form-item {
  @extend .flex-container;
  flex-wrap: wrap;
  align-items: center;

  &__label {
    flex-grow: 0;
    min-width: 30%;
    color: $colorSecondary;
    margin-right: emCalc(20);
  }

  &__input {
    flex-grow: 1;
    font-size: emCalc(18);
  }
}

/* Texts */
.text-warning {
  margin-left: emCalc(12);
  color: $colorBoschungRed;
  &__italic {
    @extend .text-warning;
    font-style: italic;
  }
}

.text-big {
  margin: emCalc(24);
  font-size: emCalc(24);
  text-align: justify;
  text-justify: inter-word;
  color: white;
}

/* Inputs */
input {
  background-color: transparent;
  &::placeholder {
    color: $placeholderDefaultColor;
  }
}

.titanic {
  float: none;
}

/* Flex containers */

.flex-row {
  @include flex-row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-column {
  @include flex-column;
  align-items: center;
  flex-wrap: wrap;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.pagination {
  margin: emCalc(24) auto;
  display: flex;
  list-style: none;
  outline: none;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.pagination > .active > a{
  background-color: $colorPrimary;
  border-color: $colorPrimary;
  color: $colorSecondary;
}
.pagination > li > a{
  border: 1px solid $colorPrimary;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: $colorSecondary;
  border-color: $colorSecondary;
  color: $colorPrimary;
  outline: none;
}
.pagination > li > a, .pagination > li > span{
  background-color: $colorSecondary;
  border-color: $colorSecondary;
  color: $colorPrimary;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
}

/* Global Text selection */
::selection {
  background: $textSelectionColor;
}
