/* Font sizes */
$browser-default-font: 16;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 500;

/* Colors */
$colorBoschungYellow: #ffcc00;
$colorBoschungTransparentYellow: rgba(255, 204, 0, 0.5);
$colorBoschungRed: rgb(255, 0, 0);
$colorDarkGrey: rgb(39, 40, 34);
$colorLightGrey: #444444;

/* Theme Colors variables */
$colorPrimary: $colorBoschungYellow;
$colorPrimaryTransparent: $colorBoschungTransparentYellow;
$colorSecondary: $colorDarkGrey;
$colorThird: $colorLightGrey;

/* Divider */
$colorDivider: lightgrey;

/* Field colors */
$colorFieldBackground: #f2f2f2;
$colorFieldBorder: #d1d1d1;

/* Border colors */
$borderDefaultColor: #cdcdcd;
$menuBorderColor: rgba(255, 255, 255, 0.3);

/* Text */
$textSelectionColor: $colorPrimary;

/* Icons */
$iconColorDefault: white;

/* Inputs */
$placeholderDefaultColor: rgb(155, 155, 155);
