@import "src/styles/main";

$formInputBackgroundColor: $colorFieldBackground;
$formInputBorderColor: $colorFieldBorder;

.signin-form-token {
  margin-bottom: emCalc(20);
  text-align: center;
}
.signin-form-recover {
  margin-bottom: emCalc(20);
  text-align: justify;
}

.domain_box {
  margin-top: emCalc(15);
  font-size: small !important;
}

.signin-form {
  &__button {
    margin: emCalc(10) 0;
    text-transform: uppercase;
    width: 100%;
  }

  &__endbutton {
    margin: auto;
    text-transform: uppercase;
    width: 80%;
    display: block;
    margin-top: emCalc(100);
  }

  &__result {
    font-size: emCalc(14);
    color: red;
    //padding: emCalc(10);
    text-align: center;
  }
}

.signin-form-actions {
  padding: emCalc(10);
  padding-top: emCalc(5);
  padding-bottom: 0;
  font-size: emCalc(14);
  color: $colorSecondary;

  //border-top: solid emCalc(1) $formInputBorderColor;

  &__action {
    @include flex-row;
    padding-top: emCalc(5);
    padding-bottom: emCalc(5);
    &__text {
      flex-grow: 1;
    }
    &__action {
      text-transform: uppercase;
      font-style: $fontWeightBold;
      &__right {
        text-transform: uppercase;
        float: right;
      }
    }
  }
}

.signin-form-item {
  display: flex;
  align-items: center;
  background-color: $formInputBackgroundColor;
  margin: 0 0 emCalc(10);
  padding: emCalc(10) emCalc(20);
  box-sizing: border-box;

  &__icon {
    width: emCalc(24);
    height: emCalc(24);
    fill: black;
  }

  &__input {
    font-size: emCalc(18);
    margin-left: emCalc(20);
    outline: 0;
    border: 0;
    background: transparent;
  }
}
