@import "src/styles/main";

$overlay-background-color: rgba(230, 230, 230, 0.8);
$header-background-color: $colorSecondary;
$header-border-color: $colorPrimary;
$footer-background-color: white;

.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $overlay-background-color;
  z-index: 1000;
}

.modal {
  @include flex-column;
  min-width: emCalc(300);
  max-width: emCalc(500);
  max-height: 90vh;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: remCalc(24);
    padding: remCalc(10);
    background-color: $header-background-color;
    border-bottom: remCalc(2) solid $header-border-color;
    font-size: emCalc(16);
    text-transform: uppercase;
    color: white;
  }

  &__content {
    @include scrollable;
    flex: 1;
    padding: emCalc(20) emCalc(40);
    background-color: white;
    text-align: justify;
  }

  &__footer {
    @include flex-row;
    justify-content: center;
    padding: emCalc(10);
    background-color: $footer-background-color;
    border-top: emCalc(1) solid $header-border-color;
  }

  &__centered {
    margin: auto;
    margin-bottom: emCalc(15);
  }

  &__text {
    margin-bottom: emCalc(15);
    display: flex;
    &__tabbed {
      margin-left: emCalc(15);
    }
  }
}
